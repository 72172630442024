import React from "react"

import Layout from "../components/layout/layout"
import SEO from "../components/seo"
import Link from "gatsby-link"

const NotFoundPage = () => (
  <Layout>
    <SEO title="Fehler 404: Seite nicht gefunden..."/>
    <section className="_13">
      <div style={{textAlign: 'center'}} className="_17">

        <h1>Seite nicht gefunden</h1>
        <p style={{ marginTop: "1rem" }}>Diese Seite wurde leider nicht gefunden. <Link className={"_4n"} to={"/"}>Hier
          kommst du zurück zur Startseite.</Link></p>
      </div>
    </section>
  </Layout>
)

export default NotFoundPage
